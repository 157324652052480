'use client'

import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SalesTaxModal from 'components/SalesTaxModal'
import {
  ExtraServiceOrderStatusModal,
  ExtraServicePaymentAuthModal,
  ExtraServiceCreditCardFormModal,
  ExtraServicePayInMethodSelectionModal,
} from 'components/ExtraService'
import BlikCodeInputModal from 'components/BlikCodeInputModal'

import useAbTest from 'hooks/useAbTest'
import useTracking from 'hooks/useTracking/useTracking'
import useExtraServiceCardAuthRedirectResultEffect from 'hooks/useExtraServiceCardAuthRedirectResultEffect'

import { navigateToPage } from 'libs/utils/window'

import * as extraServiceSelectors from 'state/extra-service/selectors'
import { actions as extraServiceActions } from 'state/extra-service/slice'

import {
  ExtraServiceOrderType,
  ExtraServiceOrderStatus,
  ExtraServiceCheckoutModal,
  CheckoutOrderTypeMap,
} from 'constants/extra-service'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { State as AppState } from 'state/types'
import {
  CreditCardModel,
  PayInMethodModel,
  CurrencyAmountModel,
  PaymentAuthStateModel,
} from 'types/models'
import { clickEvent } from 'libs/common/event-tracker/events'

import useTrackViewExtraServiceCheckout from '../hooks'

type CreditCardAddResult = {
  cardId?: string
  isError: boolean
}

export type OrderConfirmationProps = {
  show: boolean
  onBack: () => void
  onConfirmOrder: () => void
  onShowSalesTaxModal: () => void
  onPaymentMethodSelect: () => void
}

type Props = {
  orderId?: number
  entityType?: string
  isOrderFree?: boolean
  shouldSelectPaymentMethodFirst?: boolean
  orderPayable?: CurrencyAmountModel
  show: boolean
  orderType: ExtraServiceOrderType
  addCardSourceParams: ComponentProps<typeof ExtraServiceCreditCardFormModal>['addCardSourceParams']
  onBack: () => void
  onCheckoutFinish: (isSuccess: boolean) => void
  onInitStartAfterCardAuth: (orderId: number) => void
  orderConfirmationModal: (props: OrderConfirmationProps) => JSX.Element
}

const ExtraServiceCheckout = ({
  show,
  onBack,
  orderId,
  orderType,
  entityType,
  orderPayable,
  onCheckoutFinish,
  addCardSourceParams,
  orderConfirmationModal,
  onInitStartAfterCardAuth,
  isOrderFree = false,
  shouldSelectPaymentMethodFirst = true,
}: Props) => {
  const dispatch = useDispatch()
  const { track } = useTracking()

  const [cardAddResult, setCardAddResult] = useState<CreditCardAddResult | null>(null)

  const errorMessage = useSelector(extraServiceSelectors.getPaymentErrorMessage)
  const orderStatus = useSelector(extraServiceSelectors.getPaymentStatus)
  const checkoutAuth = useSelector(extraServiceSelectors.getPaymentAuth)
  const selectedCreditCard = useSelector(extraServiceSelectors.getSelectedCreditCard)
  const creditCardPayInMethod = useSelector(extraServiceSelectors.getCreditCardPayInMethod)
  const activePayInMethod = useSelector((state: AppState) =>
    extraServiceSelectors.getActivePayInMethod(state, orderPayable?.amount),
  )
  const isBlikAuthRequired = useSelector((state: AppState) =>
    extraServiceSelectors.getIsBlikAuthRequired(state, orderPayable?.amount),
  )

  const [activeModal, setActiveModal] = useState<ExtraServiceCheckoutModal>(
    ExtraServiceCheckoutModal.None,
  )

  useAbTest({
    abTestName: 'single_vas_checkout_web',
    shouldTrackExpose: activeModal === ExtraServiceCheckoutModal.OrderConfirmation,
  })
  useAbTest({
    abTestName: 'trustly_open_banking',
    shouldTrackExpose: activeModal === ExtraServiceCheckoutModal.OrderConfirmation,
  })

  useTrackViewExtraServiceCheckout({
    shouldTrack: activeModal === ExtraServiceCheckoutModal.OrderConfirmation,
    orderId,
    orderType,
    screen: Screen.Checkout,
  })

  useEffect(() => {
    if (!show) return

    dispatch(extraServiceActions.getPayInMethodsRequest())
  }, [show, dispatch])

  useExtraServiceCardAuthRedirectResultEffect(orderType, {
    onSuccess: successResult => {
      onInitStartAfterCardAuth(successResult.orderId)
      setCardAddResult({ isError: false, cardId: successResult.cardId })
    },
    onFailure: failureResult => {
      onInitStartAfterCardAuth(failureResult.orderId)
      setCardAddResult({ isError: true })
    },
  })

  const handlePayInMethodSelectionConfirm = useCallback(
    (selectedPayInMethod: PayInMethodModel, creditCardId?: string) => {
      dispatch(
        extraServiceActions.setSelectedPayInMethod({
          creditCardId,
          selectedPayInMethodId: selectedPayInMethod.id,
        }),
      )

      setActiveModal(ExtraServiceCheckoutModal.OrderConfirmation)
    },
    [dispatch, setActiveModal],
  )

  useEffect(() => {
    if (!show) {
      setActiveModal(ExtraServiceCheckoutModal.None)

      return
    }

    if (cardAddResult?.cardId && creditCardPayInMethod) {
      handlePayInMethodSelectionConfirm(creditCardPayInMethod, cardAddResult.cardId)

      return
    }

    if (cardAddResult?.isError) {
      setActiveModal(ExtraServiceCheckoutModal.CreditCardForm)

      return
    }

    if (shouldSelectPaymentMethodFirst && !activePayInMethod && !isOrderFree) {
      setActiveModal(ExtraServiceCheckoutModal.PayInMethodSelection)

      return
    }

    setActiveModal(ExtraServiceCheckoutModal.OrderConfirmation)
  }, [
    show,
    orderId,
    dispatch,
    isOrderFree,
    cardAddResult,
    activePayInMethod,
    creditCardPayInMethod,
    shouldSelectPaymentMethodFirst,
    handlePayInMethodSelectionConfirm,
  ])

  useEffect(() => {
    function handlePaymentAuth() {
      const { embedded, payInRedirectUrl } = checkoutAuth

      if (!show || activeModal === ExtraServiceCheckoutModal.None) return

      if (embedded) {
        setActiveModal(ExtraServiceCheckoutModal.PaymentAuth)

        return
      }

      if (payInRedirectUrl) {
        navigateToPage(payInRedirectUrl)
      }
    }

    handlePaymentAuth()
  }, [show, activeModal, checkoutAuth, setActiveModal])

  const confirmOrder = (blikCode?: string) => {
    if (!orderId) return

    track(
      clickEvent({
        target: ClickableElement.CheckoutPayButton,
        targetDetails: JSON.stringify({
          valid: true,
          service_order_id: orderId,
          type: CheckoutOrderTypeMap[orderType],
          payment_method: activePayInMethod?.code.toLowerCase() || null,
        }),
      }),
    )

    if (isBlikAuthRequired && !blikCode) {
      setActiveModal(ExtraServiceCheckoutModal.BlikCodeInput)

      return
    }

    dispatch(
      extraServiceActions.confirmOrderPaymentRequest({
        orderId,
        blikCode,
        orderType,
        entityType,
        selectedCreditCardId: selectedCreditCard?.id,
        selectedPaymentMethodId: activePayInMethod?.id,
      }),
    )

    setActiveModal(ExtraServiceCheckoutModal.OrderStatus)
  }

  // TODO: get rid of this callbacks by refining child component callback arguments.
  const handleConfirmOrder = () => confirmOrder()

  const handleBlikCodeInput = (blikCode: string) => confirmOrder(blikCode)

  function handleCreditCardAdd(creditCard: CreditCardModel) {
    if (!creditCardPayInMethod) return

    dispatch(extraServiceActions.addCreditCardSuccess({ creditCard }))
    handlePayInMethodSelectionConfirm(creditCardPayInMethod, creditCard.id)
  }

  function openSalesTaxModal() {
    setActiveModal(ExtraServiceCheckoutModal.SalesTaxInfo)
  }

  function openOrderConfirmationModal() {
    setActiveModal(ExtraServiceCheckoutModal.OrderConfirmation)
  }

  function openPayinMethodSelectionModal() {
    setActiveModal(ExtraServiceCheckoutModal.PayInMethodSelection)
    setCardAddResult(null)
  }

  function openCreditCardModal() {
    setActiveModal(ExtraServiceCheckoutModal.CreditCardForm)
  }

  function closeCheckoutModal() {
    setActiveModal(ExtraServiceCheckoutModal.None)
    dispatch(extraServiceActions.cancelOrderPaymentAuth())
    onCheckoutFinish(orderStatus === ExtraServiceOrderStatus.Success)
  }

  function handle3DS2AuthSubmit(state: PaymentAuthStateModel) {
    if (!checkoutAuth.embedded) return

    dispatch(
      extraServiceActions.updateOrderPaymentDataRequest({
        state,
        paymentId: checkoutAuth.embedded.paymentId,
      }),
    )

    setActiveModal(ExtraServiceCheckoutModal.OrderStatus)
  }

  function handle3DS2Error(paymentAuthErrorMessage: string) {
    dispatch(
      extraServiceActions.setOrderPaymentErrorMessage({ errorMessage: paymentAuthErrorMessage }),
    )

    setActiveModal(ExtraServiceCheckoutModal.OrderStatus)
  }

  return (
    <>
      <ExtraServicePayInMethodSelectionModal
        show={activeModal === ExtraServiceCheckoutModal.PayInMethodSelection}
        orderId={orderId}
        orderType={orderType}
        orderPayable={orderPayable}
        selectedCreditCardId={selectedCreditCard?.id}
        selectedPayInMethodId={activePayInMethod?.id}
        onBack={onBack}
        onCancel={closeCheckoutModal}
        onAddCreditCard={openCreditCardModal}
        onConfirm={handlePayInMethodSelectionConfirm}
      />
      <ExtraServiceCreditCardFormModal
        show={activeModal === ExtraServiceCheckoutModal.CreditCardForm}
        orderId={orderId}
        orderType={orderType}
        isCardAddError={cardAddResult?.isError}
        addCardSourceParams={addCardSourceParams}
        onSuccess={handleCreditCardAdd}
        onBack={openPayinMethodSelectionModal}
      />
      <ExtraServicePaymentAuthModal
        show={activeModal === ExtraServiceCheckoutModal.PaymentAuth}
        onError={handle3DS2Error}
        onCancel={closeCheckoutModal}
        onAuthSubmitted={handle3DS2AuthSubmit}
      />
      <SalesTaxModal
        show={activeModal === ExtraServiceCheckoutModal.SalesTaxInfo}
        messageType="extra_service"
        onClose={openOrderConfirmationModal}
      />
      <BlikCodeInputModal
        show={activeModal === ExtraServiceCheckoutModal.BlikCodeInput}
        onInputConfirmed={handleBlikCodeInput}
        onCancel={openOrderConfirmationModal}
      />
      <ExtraServiceOrderStatusModal
        show={activeModal === ExtraServiceCheckoutModal.OrderStatus}
        orderId={orderId}
        orderType={orderType}
        orderStatus={orderStatus}
        orderPayable={orderPayable}
        errorMessage={errorMessage}
        onContinue={closeCheckoutModal}
      />
      {orderConfirmationModal({
        show: activeModal === ExtraServiceCheckoutModal.OrderConfirmation,
        onBack,
        onConfirmOrder: handleConfirmOrder,
        onShowSalesTaxModal: openSalesTaxModal,
        onPaymentMethodSelect: openPayinMethodSelectionModal,
      })}
    </>
  )
}

export default ExtraServiceCheckout
